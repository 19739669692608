
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiWarehouses } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { Plus } from "@element-plus/icons-vue";
import JwtService from "@/core/services/JwtService";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "organisation-and-facility-warehouse-quality-inspection",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const formData = ref({
      id: route.params.id,
      sku_identifier: "",
      warehouse: "",
      inspection_status: "",
      inspection_manager: "",
      check_point: "",
      result: "",
      notice: "",
    });
    // const uploadImagesPhoto = ApiAccount.uploadAccountdPhoto(); // 头像上传
    const options = ref({
      warehouse: [],
      inspection_status: [],
      inspection_manager: [],
      check_point: [],
      uploadImagesPhoto: "",
      uploadHeaders: {
        Authorization: `Bearer ${JwtService.getToken()}`,
      },
      photoList: [],
      result: [
        {
          value: 0,
          label: "Passed",
        },
        {
          value: 1,
          label: "Failed",
        },
      ],
    });

    const rules = ref({});

    const getShowInfo = () => {
      ApiWarehouses.showWarehouse({ id: route.params.id })
        .then(({ data }) => {
          formData.value = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getTaggingData = () => {
      ApiBase.getTaggingData({
        short_key: ["qa_image_tagging_list"],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.check_point = data.data.qa_image_tagging_list.items;
            // options.value.taggings = data.data.warehouse_taggings.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo(), getTaggingData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiWarehouses.updateWarehouse(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      router.back();
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    return {
      t,
      loading,
      options,
      formData,
      formRef,
      rules,
      submitButton,
      getFromInfo,
      submit,
      backToList,
    };
  },
});
